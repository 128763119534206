import React from 'react'
import '../App.css';

const HeaderSM = ({ category, title, OtherInfo,OtherInfo1 }) => {
  return (
    <div className="mb-10">
      <p className="text-3xl font-extrabold tracking-tight text-slate-900 headerSM-title">
        {title} 
      </p>
    </div>
  )
}

export default HeaderSM